import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ballMachineProtoVideo from "../../public/media/ball-machine-prototype-480.mp4";

const Resume = () => (
  <Layout>
    <Seo title="Selected Projects" />
    <h1 style={{
      fontSize: `1.75rem`,
    }}>Selected Projects</h1>
    <h2>Paragraph Generator (<a href="https://paragraphs.cordulack.com/">Site</a> | <a href="https://github.com/cordulack/paragraphs">Code</a>)</h2>
    <p>
      This is a static application hosted on S3 and uses React. I built this as a side project while working on the logic for inserting various components into Seattle Times articles.
      Depending on the length of the article, and the number of words in a paragraph, we had to insert things like ads and
      newsletter signup forms after specific paragraphs. To simplify testing (I got sick of having to count words and
      paragraphs), I wanted to have a lorem ipsum generator that added paragraph numbers and word counts to each paragraph. The
      text came from Project Gutenberg.
    </p>
    <h2>Accessibility Demos (<a href="https://accessibility.cordulack.com/index.html">Site</a> | <a href="https://github.com/cordulack/accessibility">Code</a>)</h2>
    <p>
      I made these examples after failing to find sites that showed you incorrect and correct demos of various
      accessibility principles.
    </p>
    <h2>Tennis Ball Machine</h2>
    <p>
      <video controls>
        <source src={ballMachineProtoVideo} type="video/mp4" />
        <track kind="captions" src="/media/ball-machine-prototype-480-captions.vtt" srcLang="en" />
      </video>
    </p>
    <p>
      Even though I have yet to hit one ball shot by this machine, it has been a lot of fun building it. It uses an
      Arduino to control several motors to feed in, and shoot, the tennis balls. This is the first Arduino project I
      have gotten to the stage of having a working prototype. Solving mechanical problems draws on similar skills to
      the ones we use when tackling software engineering problems, but testing your solutions is so much more fun
      because you have a tangible artifact of your work.
    </p>
  </Layout>
)

export default Resume
